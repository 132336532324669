'use strict';

import throttle from 'lodash/throttle';

export default class GotoTop {
  constructor() {
    $('.goto-top')[($(window).scrollTop() > 100 ? 'add' : 'remove') + 'Class']('active');

    $(window).on('scroll', throttle(() => {
      $('.goto-top')[($(window).scrollTop() > 100 ? 'add' : 'remove') +  'Class']('active');
    }, 100));

    $('[data-goto-top]').on('click', function(e) {
      e.preventDefault();

      $('html, body').animate({
        scrollTop: 0
      }, 400);
    });
  }
}
