'use strict';

import _ from 'lodash';

export default class Highlight {
  constructor() {
    (function($) {
      const canvasPreviewHeight = 200;

      $.extend({
        ocrHighlight: {
          init: $obj => {
            $obj.data('has-highlight', true);

            $obj.attr('data-has-highlight', true);

            const highlightConfig = $obj.data('highlight');

            highlightConfig.forEach(item => {
              const $item = $('<div>', {
                class: 'ocr-h---content'
              });

              $item.append($('<div>', {
                class: 'ocr-h---content-header'
              }).html(item.title));

              const $img = $('<img>', item.img);

              $item.append($img);

              $item.append($('<canvas>', {
                width: 1024,
                height: canvasPreviewHeight
              }));

              $obj.append($item);

              $img.on('load', function() {
                $.ocrHighlight.render($item, item);
              });
            });
          },
          render: ($obj, highlightConfig) => {
            const $image = $obj.children('img');

            $image.css({
              position: 'fixed',
              top: '-100000000px',
              left: '-10000000px'
            })

            $('body').append($image);

            const $canvas = $obj.children('canvas');

            $canvas.attr({
              width: $image.width(),
              height: $image.height()
            });

            const  drawing = $canvas.get(0);

            const con = drawing.getContext('2d');

            const goofyPic = $image.get(0);

            con.drawImage(goofyPic, 0, 0, $image.width(), $image.height());

            highlightConfig.highlights.forEach(highlight => {
              const ctx = drawing.getContext('2d');

              ctx.rect(...highlight.coords);

              ctx.strokeStyle = 'red';

              ctx.stroke();
            });

            const cropCanvas = (sourceCanvas, left, top, width, height) => {
              if( !height || !width ){
                return null;
              }

              const $destCanvas = $('<canvas>');

              const destCanvas = $destCanvas.get(0);

              destCanvas.width = width;

              destCanvas.height = height;

              destCanvas.getContext("2d").drawImage(
                sourceCanvas,
                left,
                top,
                width,
                height,
                0,
                0,
                width,
                height
              );

              return $destCanvas;
            };

            let top = highlightConfig.highlights[0].coords[1];

            top = top - 10 < 0 ? 0 : top - 10;

            if ($image.height() - top < canvasPreviewHeight) {
              top = $image.height() - canvasPreviewHeight;
            }

            const $croppedCanvas = cropCanvas(drawing, 0, top, $image.width(), ($image.width() / 800) * canvasPreviewHeight);

            $canvas.replaceWith($croppedCanvas);

            $image.remove();
          }
        }
      });
    })($);

    const initHightlight = () => {
      $('[data-highlight]:not([data-has-highlight])').each(function() {
        $.ocrHighlight.init($(this));
      });
    };

    initHightlight();

    $(document).on('handlebars.render.end', _.debounce(initHightlight, 100));
  }
};
