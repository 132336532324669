'use strict';

import isArray from 'lodash/isArray';
import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';
import set from 'lodash/set';
import get from 'lodash/get';
import unset from 'lodash/unset';
import cloneDeep from 'lodash/cloneDeep';
import { ajax } from '../../_scripts/commons/ajax'

export default class InfiniteScroll {
  constructor() {
    (function($) {
      $.extend({
        infiniteScroll: {
          init: ($obj) => {
            return $.infiniteScroll.render($obj);
          },
          render: ($obj) => {
            const config = {
              page: 1,
              pageParam: 'page',
              type: 'get',
              isParams: null,
              lastItemForPagination: false,
              ...$obj.data('infinite-scroll')
            };

            const isParams = (config.isParams && $(config.isParams).data('infinite-scroll')) || {}

            const loading = !(!$obj.data('loading'));

            let currentPage = $obj.data('page') || parseInt((config.params || {})[config.pageParam] || config.page);

            const isFirstPage = !(!$obj.data('first-page')) || (currentPage === 1);

            $obj.data('first-page', false);

            $obj.attr('data-first-page', false);

            const data = {
              ...(isParams.params || {}),
              ...(config.params || {})
            }

            if (config.lastItemForPagination) {
              set(data, currentPage.name, currentPage.value);
            } else if (!config.pagination) {
              set(data, config.pageParam, currentPage);
            }

            if (!loading) {
              $obj.data('loading', true);

              $obj.attr('data-loading', true);

              const renderContainer = (jsonData, json, config) => {
                const jsonTitle = jsonData.hasOwnProperty('totalCount') ? jsonData.totalCount  : json.title

                $(config.title).html('');

                if ((config.title && jsonTitle) || jsonTitle === 0) {
                  $(config.title).html(jsonTitle);
                }

                $.handlebars({
                  template: config.template,
                  context: jsonData,
                  append: isFirstPage || config.classicPagination ? 'html' : 'append',
                  container: config.container
                });
              }

              const renderError = () => {
                $obj.data('loading', false);

                $obj.attr('data-loading', false);

                $obj.addClass('d-none');

                return (config.containers ? config.containers : [{ container: config.container }]).forEach(item => {
                  $.handlebars({
                    template: '#default-error-template',
                    context: {},
                    append: 'html',
                    container: item.container
                  });
                });
              };

              ajax.run({
                url: config.url,
                type: config.type,
                data,
              }).then(json => {
                if (json.status !== 'success') {
                  return renderError();
                }

                const jsonData = json.data || {
                  noData: true
                }

                if (config.containers) {
                  config.containers.forEach(item => {
                    if (isFirstPage || !item.onFirstPage) {
                      renderContainer(config.appendToResult ? {
                        data: jsonData,
                        globals: {
                          ...config.appendToResult,
                          params: config.params,
                          data
                        }
                      } : jsonData, json, item, currentPage);
                    }
                  })
                } else {
                  renderContainer(config.appendToResult ? {
                    data: jsonData,
                    globals: {
                      ...config.appendToResult,
                      data
                    }
                  } : jsonData, json, config, currentPage);
                }

                $obj.data('loading', false);

                $obj.attr('data-loading', false);

                let nextPage = 0;

                if (config.lastItemForPagination) {
                  nextPage = currentPage;

                  nextPage.value = json.data[json.data.length - 1][currentPage.item];
                } else {
                  nextPage = currentPage + 1;
                }

                $obj.data('page', nextPage);

                $obj.attr('data-page', config.lastItemForPagination ? JSON.stringify(nextPage) : nextPage);

                $obj.removeClass('d-none');

                if (!jsonData.hasMore && !json.hasMore) {
                  $obj.addClass('d-none');
                }
              }).catch(err => {
                console.error(err);

                renderError();
              });
            }
          },
          includeExclude: (includeExclude, isChecked, infiniteScrollParams) => {
            const type = {
              curr: includeExclude.type,
              other: includeExclude.type === 'include' ? 'exclude' : 'include',
            }

            if (!includeExclude || !includeExclude.params) {
              return infiniteScrollParams;
            }

            if (!infiniteScrollParams.exclude) {
              infiniteScrollParams.exclude = {};
            }

            if (!infiniteScrollParams.include) {
              infiniteScrollParams.include = {};
            }

            Object.entries(includeExclude.params).forEach(([key, value]) => {
              let findedItem = get(infiniteScrollParams[type.curr], key);

              value = isArray(value) ? value : [value];

              if (!isChecked) {
                if (!findedItem) {
                  return;
                }

                value.forEach(tmpValue => {
                  let itemIndex = findedItem.indexOf(tmpValue);

                  if (itemIndex > -1) {
                    findedItem.splice(itemIndex, 1);
                  }
                });

                set(infiniteScrollParams[type.curr], key, findedItem);

                return;
              }

              let otherFindedItem = get(infiniteScrollParams[type.other], key);

              if (otherFindedItem) {
                value.forEach(tmpValue => {
                  let otherItemIndex = otherFindedItem.indexOf(tmpValue);

                  if (otherItemIndex > -1) {
                    otherFindedItem.splice(otherItemIndex, 1);
                  }
                });

                set(infiniteScrollParams[type.other], key, otherFindedItem);
              }

              if (!findedItem) {
                set(infiniteScrollParams[type.curr], key, value);

                return;
              }

              value.forEach(tmpValue => {
                let itemIndex = findedItem.indexOf(tmpValue);

                if (itemIndex === -1) {
                  findedItem.push(tmpValue)
                }
              });

              set(infiniteScrollParams[type.curr], key, findedItem);
            });

            return infiniteScrollParams;
          },
          removeParams: (infiniteScroll, params) => {
            const $obj = $(infiniteScroll);

            const infiniteScrollConfig = $obj.data('infinite-scroll');

            params.forEach(param => {
              const findedParam = get(infiniteScrollConfig.params, param.name);

              if (findedParam) {
                if (isArray(findedParam)) {
                  const findedIndex = findedParam.indexOf(param.value);

                  if (findedIndex > -1) {
                    findedParam.splice(findedIndex, 1);
                  }

                  set(infiniteScrollConfig.params, param.name, findedParam);
                } else {
                  unset(infiniteScrollConfig.params, param.name);
                }
              }
            });

            $obj.data('infinite-scroll', infiniteScrollConfig);

            $obj.attr('data-infinite-scroll', JSON.stringify(infiniteScrollConfig));

            $obj.data('page', null);

            $obj.attr('data-page', null);

            $.infiniteScroll.init($obj);
          },
          toggleParams: ($obj, params, includeExclude, isChecked) => {
            const infiniteScroll = $obj.data('infinite-scroll');

            if (!infiniteScroll) {
              return;
            }

            let infiniteScrollParams = cloneDeep(infiniteScroll.params);

            Object.entries(params).forEach(([key, value]) => {
              if (key.endsWith('[]')) {
                if (isArray(infiniteScrollParams[key])) {
                  if (infiniteScrollParams[key].indexOf(value) === -1) {
                    infiniteScrollParams[key].push(value);
                  }

                  return;
                }

                return infiniteScrollParams[key] = [
                  value
                ];
              }

              infiniteScrollParams[key] = value;
            });

            if (includeExclude) {
              infiniteScrollParams = $.infiniteScroll.includeExclude(includeExclude, isChecked, infiniteScrollParams);
            }

            set(infiniteScroll, infiniteScroll.paramsNode || 'params', infiniteScrollParams);

            $obj.data('infinite-scroll', infiniteScroll);

            $obj.attr('data-infinite-scroll', JSON.stringify(infiniteScroll));

            $obj.data('page', null);

            $obj.attr('data-page', null);

            $.infiniteScroll.init($obj);
          }
        },
      });
    })($);

    $('[data-infinite-scroll]:visible').each(function() {
      $.infiniteScroll.init($(this));
    });

    $('body').on('shown.bs.tab', '[data-toggle="tab"]', function () {
      $('[data-infinite-scroll]:visible').each(function () {
        $.infiniteScroll.init($(this));
      });
    });

    $('body').on('shown.bs.modal', '#dynamic-modal', function () {
      $(this).find('[data-infinite-scroll]:visible').each(function () {
        $.infiniteScroll.init($(this));
      });
    });

    let forceScrollEvent = null;

    const scrollEvent = function() {
      if (forceScrollEvent) {
        clearTimeout(forceScrollEvent);
      }

      // Carico risultati se lo scroll event non è partito.
      forceScrollEvent = setTimeout(scrollEvent, 2000);

      $('[data-infinite-scroll]:visible').each(function () {
        const inViewport = $.inViewport($(this), 600) && $(this).is(':visible');

        if (inViewport) {
          return $.infiniteScroll.render($(this));
        }
      });
    };

    scrollEvent();

    $(window).on('scroll', throttle(scrollEvent, 100));

    const infiniteScroll = () => {
      $('.modal-body:not([data-has-scroll-event]), div[class*="sv-"]:not([data-has-scroll-event])').each(function() {
        $(this).data('has-scroll-event', true);

        $(this).attr('data-has-scroll-event', true);

        $(this).on('scroll', throttle(scrollEvent, 100));
      });
    }

    infiniteScroll();

    $(document).on('handlebars.render.end', debounce(() => {
      infiniteScroll();
    }, 300));

    $('body').on('click', '[data-infinite-scroll-sort]', function(e) {
      e.preventDefault();

      const $obj = $(this);

      const sortConfig = $obj.data('infinite-scroll-sort');

      const changeSort = (infiniteScroll, $obj) => {
        const infiniteScrollConfig = $(infiniteScroll).data('infinite-scroll');

        const params = {
          sort: {
            name: sortConfig.name
          }
        };

        let currentSort = infiniteScrollConfig.params.sort;

        if (currentSort && currentSort.name === sortConfig.name) {
          params.sort.type = sortConfig.types[sortConfig.type === 'asc' ? 'desc' : 'asc'];

          sortConfig.type = sortConfig.type === 'asc' ? 'desc' : 'asc';
        } else {
          params.sort.type = sortConfig.types.asc;

          sortConfig.type = 'asc';
        }

        $.infiniteScroll.toggleParams($(infiniteScroll), params);

        $obj.closest('thead').find('[data-infinite-scroll-sort]').removeClass('th-sort').removeClass('th-sort-asc').removeClass('th-sort-desc');

        $obj.addClass('th-sort').addClass('th-sort-' + sortConfig.type);

        $obj.data('infinite-scroll-sort', sortConfig);

        $obj.attr('data-infinite-scroll-sort', JSON.stringify(sortConfig));
      }

      if (typeof sortConfig.infiniteScroll === 'string') {
        return changeSort(sortConfig.infiniteScroll, $obj);
      }

      return sortConfig.infiniteScroll.forEach(infiniteScroll => {
        changeSort(infiniteScroll, $obj);
      });
    });

    $('body').on('keyup', '[data-infinite-scroll-search]', debounce(function () {
      const searchConfig = $(this).data('infinite-scroll-search');

      const params = {};

      params[$(this).attr('name')] = $(this).val();

      if (typeof searchConfig.infiniteScroll === 'string') {
        return $.infiniteScroll.toggleParams($(searchConfig.infiniteScroll), params);
      }

      searchConfig.infiniteScroll.forEach(item => {
        $.infiniteScroll.toggleParams($(item), params);
      });
    }, 300));

    const toggleInfiniteScrollParams = $obj => {
      let { infiniteScroll, params} = $obj.data('toggle-infinite-scroll-params');

      if (!params) {
        params = {};

        const isCheckbox = $obj.is(':checkbox');

        const isChecked = isCheckbox ? $obj.is(':checked') : true;

        // simulate the behaviour of radio button with checkboxes
        if ($obj.is('[data-radio]') && isChecked) {
          let radioName = $obj.data('radio');
          $(`[data-radio=${radioName}]`).each(function() {
            this.checked = false;
            params[$(this).attr('name')] = null;
          })

          $obj.get(0).checked = true;
        }

        params[$obj.attr('name')] = isChecked ? $obj.val() : null;
      }

      if (typeof infiniteScroll === 'string') {
        return $.infiniteScroll.toggleParams($(infiniteScroll), params);
      }

      infiniteScroll.forEach(item => {
        $.infiniteScroll.toggleParams($(item), params);
      });

      const $resetScroll = $('[data-reset-scroll]');

      setTimeout(() => {
        if ($resetScroll.length) {
          $(window).scrollTop($resetScroll.offset().top - $('.main-header').height());
        }
      }, 1000);
    };

    $('body').on('click', '[data-remove-infinite-scroll-params]', function(e) {
      e.preventDefault();

      const removeParamsConfig = $(this).data('remove-infinite-scroll-params') || {};

      const { infiniteScroll, params } = removeParamsConfig || {};

      if (typeof infiniteScroll === 'string') {
        return $.infiniteScroll.removeParams(infiniteScroll, params);
      }

      infiniteScroll.forEach(item => {
        return $.infiniteScroll.removeParams(item, params);
      });
    });

    const changeIncludeExclude = (_this, isCheckbox = true) => {
      let { infiniteScroll, type, params } = $(_this).data('include-exclude');

      const isChecked = $(_this).is(':checked');

      if (isChecked) {
        $(_this).closest('[data-include-exclude-block]').find('[data-include-exclude]:checked').each(function() {
          if (this !== _this) {
            $(this).prop('checked', false);
          }
        });
      }

      const tmpParams = isArray(params) ? {} : cloneDeep(params);

      if (isArray(params)) {
        params.forEach(param => {
          tmpParams[param.name] = param.value;
        });
      }

      if (typeof infiniteScroll === 'string') {
        return $.infiniteScroll.toggleParams($(infiniteScroll), {}, {
          type,
          params: tmpParams
        }, isCheckbox ? isChecked : false);
      }

      infiniteScroll.forEach(item => {
        $.infiniteScroll.toggleParams($(item), {}, {
          type,
          params: tmpParams
        }, isCheckbox ? isChecked : false);
      });
    }

    $('body').on('change', '[data-include-exclude]', function() {
      changeIncludeExclude(this);
    });

    $('body').on('click', 'a[data-include-exclude]', function (e) {
      e.preventDefault();

      changeIncludeExclude(this, false);
    });

    $('body').on('change', '[data-toggle="check-all"]', function () {
      const checkAllConfig = $(this).data('target');

      const isChecked = $(this).is(':checked');

      const $currentTargets = $(checkAllConfig[checkAllConfig.type]);

      $(checkAllConfig[checkAllConfig.type]).prop('checked', isChecked);

      if (isChecked) {
        $(checkAllConfig[checkAllConfig.type === 'include' ? 'exclude' : 'include']).prop('checked', false);
      }

      let finalConfig = {};

      const tmpParams = {};

      $currentTargets.each(function () {
        const currentTargetConfig = $(this).data('include-exclude');

        if (!currentTargetConfig) {
          return;
        }

        if (!finalConfig.params) {
          finalConfig = currentTargetConfig;
        }

        Object.entries(currentTargetConfig.params).forEach(([key, value]) => {
          if (!tmpParams[key]) {
            tmpParams[key] = [];
          }

          if (tmpParams[key].indexOf(value) === -1) {
            tmpParams[key].push(value);
          }
        });
      });

      finalConfig.params = tmpParams;

      const $toChangeElement = $(this).parent().children('[data-include-exclude]');

      $toChangeElement.data('include-exclude', finalConfig);

      $toChangeElement.attr('data-include-exclude', JSON.stringify(finalConfig));

      $toChangeElement.prop('checked', isChecked);

      $toChangeElement.change();
    });

    $('body').on('change change:range', 'input[data-toggle-infinite-scroll-params], select[data-toggle-infinite-scroll-params]', function() {
      toggleInfiniteScrollParams($(this));
    });

    $('body').on('click', '[data-toggle-infinite-scroll-params]:not(input):not(select)', function(e) {
      e.preventDefault();

      toggleInfiniteScrollParams($(this));
    });

    $('body').on('click', '[data-sort]', function(e) {
      e.preventDefault();

      const sortConfig = $(this).data('sort');

      sortConfig.infiniteScroll.forEach(item => {
        $.infiniteScroll.toggleParams($(item), {
          sort: sortConfig.sort,
          page: 1
        });
      })
    });
  }
}
