'use strict';

export default class TabIndex {
  constructor() {
    const initTabIndex = () => {
      $('input:not([type="hidden"]), select, textarea').each(function(index) {
        $(this).attr('tabindex', index + 1);
      });
    };

    initTabIndex();

    $(document).on('handlebars.render.end', initTabIndex);
  }
}
