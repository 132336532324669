'use strict';

import { ajax } from '../../_scripts/commons/ajax';

export default class Card {
  constructor() {
    $('body').on('click', '[data-toggle="class"]', function(e) {
      e.preventDefault();

      const $target = $($(this).attr('href') || $(this).data('target'));

      const className = $(this).data('class');

      const labels = $(this).data('labels');

      $(this).html(labels[$target.hasClass(className) ? 'on' : 'off']);

      $target.toggleClass(className);
    });

    $('body').on('click', '[data-trigger="post"]', function(e) {
      e.preventDefault();

      const $target = $($(this).attr('href') || $(this).data('target'));

      const items = [];

      const allUnchecked = !$target.find('[data-to-post-item]:checked').length;

      $target.find('[data-to-post-item]').each(function() {
        if (allUnchecked || ($(this).is(':checkbox') && $(this).is(':checked'))) {
          items.push($(this).data('to-post-item'));
        }
      });

      const $input = $('<input>', {
        name: $(this).data('name') || 'items'
      });

      $input.val(JSON.stringify(items));

      const $form = $('<form>', {
        action: $(this).data('url'),
        method: 'post',
        target: $(this).attr('target')
      });

      $form.html($input);

      $('body').append($form);

      $form.submit();
    });

    $('body').on('click', '[data-card-image-zoom]', function(e) {
      e.preventDefault();

      const zoomStep = 30;

      const isZoomIn = $(this).data('card-image-zoom') === 'in';

      const $target = $(this).closest('.card').find('.card-body .card-image img');

      const currentWidth = parseInt($target.css('width').replace(/^([0-9]+)/ig, '$1')) || $target.width();

      const newWidth = currentWidth + (isZoomIn ? 30 : -30);

      $target.css({
        width: newWidth > zoomStep ? newWidth : zoomStep
      });
    });
  }
}
