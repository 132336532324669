'use strict';

export default class Goto {
  constructor() {
    (function($) {
      $.extend({
        goto: {
          withParams: $obj => {
            const gotoConfig = $obj.data('goto-with-selection');

            const currentUrl = $obj.attr('href');

            const separator = currentUrl.indexOf('?') > -1 ? '&' : '?';

            const data = {};

            if (gotoConfig.pagination) {
              const $paginationContainer = $(gotoConfig.pagination);

              data.page = $paginationContainer.data('page') || 2;

              data.page = data.page - 1;
            }

            if (gotoConfig.selection) {
              const tmpSelected = $('[data-selected-results]').val();

              if (tmpSelected !== '[]') {
                data[gotoConfig.selection.name] = tmpSelected;
              }
            }

            window.location.href = currentUrl + separator + $.param(data);
          }
        }
      });
    })($);

    $('body').on('click', '[data-goto-with-selection]', function(e) {
      e.preventDefault();

      $.goto.withParams($(this));
    });
  }
}
