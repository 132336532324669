'use strict';

export default class JQueryHelpers {
  constructor() {
    $('body').on('click', '[data-toggle="collapse-all-collapse"], [data-toggle="collapse-all-show"]', function(e) {
      e.preventDefault();

      const $target = $($(this).attr('href') || $(this).data('target'));

      const collapseAll = $(this).data('toggle') === 'collapse-all-collapse';

      $(this).toggleClass('d-none');

      $(this).siblings().toggleClass('d-none');

      $target.find('[data-toggle="collapse"][aria-expanded="' + (collapseAll ? 'true' : 'false') + '"]').each(function() {
        $(this).trigger('click');
      });

      if(collapseAll & !document.cookie.includes('hideAll')){
        document.cookie = "hideAll=true; path=/";
      }

      if(!collapseAll & document.cookie.includes('hideAll')){
        document.cookie = "hideAll=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      }
    });

    $(document).on('handlebars.render.end', function() {
      if(document.cookie.includes('hideAll')){
        $('[data-toggle="collapse-all-collapse"]').trigger('click');
      }
    });

    $('body').on('change', '[data-toggle="check-all"]', function() {
      const current = this;

      const selectAll = $(current).data('toggle') === 'check-all';

      const checked = selectAll ? $(current).is(':checked') : false;

      $(current).closest('form').find('[type="checkbox"]').each(function () {
        if (current !== this) {
          $(this).attr('checked', checked);
        }
      });
    });

    $('body').on('click', '[data-toggle="group-items"]', function(e) {
      e.preventDefault();

      const $groupList = $(this).closest('.list-group');

      const isToggled = $(this).data('toggled');

      $(this).data('toggled', !isToggled);

      $(this).attr('data-toggled', !isToggled);

      $(this).find('[data-not-toggled]')[(!isToggled ? 'add' : 'remove') + 'Class']('d-none');

      $(this).find('[data-toggled]')[(isToggled ? 'add' : 'remove') + 'Class']('d-none');

      $groupList.find('[data-to-toggle="true"]').toggleClass($(this).data('to-toggle'));
    });
  }
}
