'use strict';

import { ajax } from "./commons/ajax";

export default class Ajax {
  constructor() {
    (function($) {
      $.extend({
        ajaxActions: {
          toggleItemFrom: $obj => {
            const ajaxActionConfig = $obj.data('ajax-action');

            const selected = $obj.data('selected');

            const { url, type = 'get', container, template } = ajaxActionConfig;

            const setSelected = selected => {
              $obj.data('selected', selected);

              $obj.attr('data-selected', selected);
            }

            ajax.run({
              url,
              type
            }).then(json => {
              if (json.status === 'success') {
                const $existingItem = $(container).find('[data-id="' + json.data.id + '"]');

                setSelected(!selected);

                if (!$existingItem.length && !selected) {
                  $.handlebars({
                    container,
                    template,
                    append: 'append',
                    context: json.data
                  });
                } else if (selected && $existingItem.length) {
                  $existingItem.remove();
                }
              }
            }).catch(err => {
              console.log(err);
            });
          }
        },
        ajaxAction: $obj => {
          const ajaxActionConfig = $obj.data('ajax-action');

          try {
            $.ajaxActions[ajaxActionConfig.action]($obj);
          } catch (err) {
            console.log(err);
          }
        }
      });
    })($);

    $('body').on('click', '[data-ajax-action]', function(e) {
      e.preventDefault();

      $.ajaxAction($(this));
    });
  }
}
