'use strict';

import uniqueId from 'lodash/uniqueId';
import _ from 'lodash';

export default class MusicSheet {
  constructor() {
    const musicSheet = () => {
      $('[data-music-sheet]:not([data-has-music-sheet])').each(function() {
        const musicSheetId = uniqueId('music-sheet-');

        const musicSheetConfig = $(this).data('music-sheet');

        $(this).data('has-music-sheet', true);

        $(this).attr('data-has-music-sheet', true);

        $(this).attr('id', musicSheetId);

        var verovioToolkit = new verovio.toolkit();

        const load_data = data => {
          const svg = verovioToolkit.renderData(data + "\n", {
            pageHeight: 2970,
            pageWidth: 1800,
            adjustPageHeight: 1,
            spacingStaff: 0,
            scale: 30
          });

          $(this).html(svg);
        };

        load_data(musicSheetConfig.content);
      });
    };

    musicSheet();

    $(document).on('handlebars.render.end', _.debounce(musicSheet, 100));
  }
};
