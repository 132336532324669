'use strict';

import InViewport from './in-viewport';
import JQueryHelpers from './jquery';
import Goto from './goto';
import CompareImage from './compare-img';

export default class Commons {
  constructor() {
    new InViewport();
    new JQueryHelpers();
    new Goto();
    new CompareImage
  }
}
