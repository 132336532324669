'use strict';

export default class Periodical {
  constructor() {
    const renderPeriodicalChildre = $obj => {
      const periodicalConfig = $obj.data('periodical');

      $.handlebars({
        ...periodicalConfig,
        container: $obj.closest('[data-periodical-container]'),
        append: 'append'
      });
    };

    const renderPeriodicalMirador = $obj => {
      $.handlebars($obj.data('periodical-mirador') || {
        context: null,
        template: null
      });
    };

    const initPeriodical = () => {
      $('.periodical-tab-item:not([data-has-periodical]) [data-periodical]:first-child').each(function() {
        const $parent = $(this).closest('.periodical-tab-item:not([data-has-periodical])');

        if ($parent.length) {
          $parent.data('has-periodical', true).attr('data-has-periodical', true);

          renderPeriodicalChildre($(this));
        }
      });

      $('.periodical-tab-item:not([data-has-periodical]) [data-periodical-mirador]:first-child').each(function() {
        const $parent = $(this).closest('.periodical-tab-item:not([data-has-periodical])');

        if ($parent.length) {
          $parent.data('has-periodical', true).attr('data-has-periodical', true);

          renderPeriodicalMirador($(this));
        }
      });
    };

    $('body').on('click', '[data-periodical], [data-periodical-mirador]', function(e) {
      e.preventDefault();

      if ($(this).hasClass('active')) {
        return;
      }

      const $parent = $(this).closest('.periodical-tab-item');

      const $dropdown = $(this).closest('.dropdown');

      const $dropdownLabel = $dropdown.children('[data-toggle="dropdown"]');

      $dropdown.find('.dropdown-item').removeClass('active');

      $parent.nextAll('.periodical-tab-item').remove();

      $dropdownLabel.html($(this).html());

      $(this).addClass('active');

      if ($(this).data('periodical')) {
        return renderPeriodicalChildre($(this));
      }

      renderPeriodicalMirador($(this));
    });

    initPeriodical();

    $(document).on('handlebars.render.end', initPeriodical);
  }
};
