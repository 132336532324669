'use strict';

import { ajax } from '../../_scripts/commons/ajax';
import debounce from 'lodash/debounce';

export default class Autocomplete {
  constructor() {
    (function($) {
      $.extend({
        autocomplete: {
          init: $obj => {
            $obj.on('keyup', debounce(function() {
              $.autocomplete.run($obj);

              $obj.closest('[data-autocomplete-block]').addClass('autocomplete-block-focus');
            }, 200));

            $obj.on('focus', function() {
              $obj.closest('[data-autocomplete-block]').addClass('autocomplete-block-focus');
            });

            $obj.closest('[data-autocomplete-block]').on('click', '[data-add-value]', function(e) {
              e.preventDefault();

              $(this).addClass('active');

              $.autocomplete.addValue($obj, $(this));
            });

            $obj.closest('[data-autocomplete-block]').on('click', '[data-remove-values]', function(e) {
              e.preventDefault();

              const $valuesGroup = $(this).closest('[data-values-group]');

              $valuesGroup.find('[data-remove-value]').each(function() {
                $.autocomplete.removeValue($obj, $(this));
              });

              $valuesGroup.remove();
            });

            $obj.closest('[data-autocomplete-block]').on('click', '[data-remove-value]', function(e) {
              e.preventDefault();

              $.autocomplete.removeValue($obj, $(this));
            });

            $obj.closest('[data-autocomplete-block]').on('click', '[data-add-values]', function(e) {
              e.preventDefault();

              const values = $(this).data('add-values');

              values.forEach(value => {
                $(this).closest('[data-autocomplete-block]').find('[data-add-value="' + value + '"]').trigger('click');
              });
            });
          },
          close: () => {
            $('.autocomplete-block-focus [data-autocomplete]').each(function() {
              $(this).val('');
            });

            $('.autocomplete-block-focus').removeClass('autocomplete-block-focus');
          },
          run: $obj => {
            const {
              url,
              type = 'get',
              name = 'query',
              minLength = 3,
              relateds
            } = $obj.data('autocomplete') || {};

            const prevValue = $obj.data('prev-value');

            const value = $obj.val();

            if (value.length < minLength) {
              return $.autocomplete.render($obj, {
                minLengthError: true
              }, value);
            }

            if (!value) {
              return $.autocomplete.render($obj, [], value);
            }

            if (value !== prevValue) {
              const data = {};

              data[name] = value;

              (relateds || []).forEach(related => {
                const inputType = $(related).attr('type');

                const relatedValue = ['radio', 'checkbox'].indexOf(inputType) === -1 ? $(related).val() : $(related).is(':checked') ? $(related).val() || true : false;

                if (relatedValue) {
                  data[$(related).attr('name')] = relatedValue;
                }
              });

              $obj.data('prev-value', value);

              $obj.attr('data-prev-value', value);

              ajax.run({
                url,
                type,
                data
              }).then(json => {
                $.autocomplete.render($obj, json.data, value);
              }).catch(err => {
                console.log(err);
              });
            }
          },
          render: ($obj, data, value) => {
            const { templates, values = [] } = $obj.data('autocomplete') || {};

            const $resultsContainer = $obj.closest('.autocomplete-block-results').find('[data-results]');

            const $close = $obj.closest('.autocomplete-block-results').find('[data-close-autocomplete]').length ? $obj.closest('.autocomplete-block-results').find('[data-close-autocomplete]') : $('<a>', {
              href: '#',
              'data-close-autocomplete': true
            });

            $close.html($('<i>', {
              class: 'material-icons'
            }).html('close'));

            $close.css({
              right: 'auto',
              left: $obj.width() - 8
            });

            $obj.closest('.autocomplete-block-results').append($close);

            $.handlebars({
              template: templates.results,
              context: {
                data,
                values,
                value
              },
              append: 'html',
              container: $resultsContainer
            });
          },
          removeValue: ($obj, $item) => {
            const autompleteConfig = $obj.data('autocomplete') || {};

            const value = $item.data('remove-value');

            const $resultsContainer = $obj.closest('[data-autocomplete-block]').find('[data-values-container]');

            const valueIndex = (autompleteConfig.values || []).indexOf(value);

            if (valueIndex > -1) {
              autompleteConfig.values.splice(valueIndex, 1);

              $obj.closest('[data-autocomplete-block]').find('[data-add-value]').removeClass('active');

              autompleteConfig.values.forEach(item => {
                $obj.closest('[data-autocomplete-block]').find('[data-add-value="' + item + '"]').addClass('active');
              });

              delete autompleteConfig.valueConfig[value];

              $.handlebars({
                template: autompleteConfig.templates.values,
                context: autompleteConfig,
                append: 'html',
                container: $resultsContainer
              });

              $obj.data('autocomplete', autompleteConfig);

              $obj.attr('data-autocomplete', JSON.stringify(autompleteConfig));
            }
          },
          addValue: ($obj, $item) => {
            const autompleteConfig = $obj.data('autocomplete') || {};

            const value = $item.data('add-value');

            const valueConfig = $item.data('value-config');

            const $resultsContainer = $obj.closest('[data-autocomplete-block]').find('[data-values-container]');

            if ((autompleteConfig.values || []).indexOf(value) === -1) {
              if (!autompleteConfig.valueConfig) {
                autompleteConfig.valueConfig = {};
              }

              autompleteConfig.valueConfig[value] = valueConfig;

              if (!autompleteConfig.values) {
                autompleteConfig.values = [];
              }

              autompleteConfig.values.push(value);
            }

            $.handlebars({
              template: autompleteConfig.templates.values,
              context: autompleteConfig,
              append: 'html',
              container: $resultsContainer
            });

            $obj.data('autocomplete', autompleteConfig);

            $obj.attr('data-autocomplete', JSON.stringify(autompleteConfig));
          }
        }
      });
    })($);

    const autocomplete = () => {
      $('[data-autocomplete]:not([data-has-autocomplete])').each(function() {
        $(this).data('has-autocomplete', true);

        $(this).attr('data-has-autocomplete', true);

        $.autocomplete.init($(this));
      });
    };

    autocomplete();

    $('body').on('change', '[data-autocomplete]', function(e) {
      e.preventDefault();

      const autocompleteConfig = $(this).data('autocomplete');

      if (autocompleteConfig.select && autocompleteConfig.select.name) {

      }
    });

    $('body').on('click', '.autocomplete-block', function(e) {
      e.stopPropagation();

      $('.autocomplete-block').removeClass('autocomplete-block-focus');

      $(this).addClass('autocomplete-block-focus');
    });

    $('body').on('click', function(e) {
      $('.autocomplete-block').removeClass('autocomplete-block-focus');
    });

    $('body').on('click', '[data-close-autocomplete]', function(e) {
      e.preventDefault();
      e.stopPropagation();

      $.autocomplete.close();
    });

    $(document).on('handlebars.render.end', function() {
      autocomplete();
    });

    $('body').on('shown.bs.tab', '[data-toggle="tab"]', function() {
      autocomplete();
    });

    $('body').on('click', '[data-autocomplete-block] [data-results] [data-change-value]', function(e) {
      e.preventDefault();
      e.stopPropagation();

      const $target = $(this).closest('[data-autocomplete-block]').find('[data-autocomplete]');

      $target.val($(this).data('change-value'));

      $target.change();

      const autocompleteConfig = $target.data('autocomplete');

      if (autocompleteConfig.select && autocompleteConfig.select.name) {
        $('[name="' + autocompleteConfig.select.name + '"]').val(autocompleteConfig.select.value);

        $('[name="' + autocompleteConfig.select.name + '"]').change();
      }

      $('.autocomplete-block-focus').removeClass('autocomplete-block-focus');
    });

    $('body').on('click', function(e) {
      const $target = $(e.target);

      const isBlock = $target.hasClass('autocomplete-block-focus');

      const hasParent = !(!$target.closest('.autocomplete-block-focus').length);

      if (!isBlock && !hasParent) {
        $.autocomplete.close();
      }
    });

    $('body').on('keydown', '[data-autocomplete][name="item:4088:fondi"]', function(e) {
      if(e.key === "Enter") {
        e.preventDefault();
      }
    });
  }
}
