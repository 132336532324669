'use strict';

export default class Modal {
  constructor() {
    (function($) {
      $.extend({
        confirm: {
          init: $obj => {
            const confirmConfig = $obj.data('confirm');

            $.handlebars({
              template: '#confirm-modal-template',
              context: confirmConfig,
              append: 'html',
              container: '#confirm-modal'
            });

            $('#confirm-modal').modal('toggle');
          },
          destroy: $obj => {}
        }
      });
    })($);

    $('body').on('click', '[data-confirm]', function(e) {
      e.preventDefault();

      $.confirm.init($(this));
    });

    $('body').on('click', '[data-toggle="dynamic-modal"]', function(e) {
      e.preventDefault();

      const $dynamicModal = $('#dynamic-modal');

      if ($dynamicModal.length) {
        const dynamicModalConfig = $(this).data('modal-config');

        $.handlebars({
          template: '#dynamic-modal-template',
          context: dynamicModalConfig,
          append: 'html',
          container: $dynamicModal
        });

        $dynamicModal.modal('toggle');
      }
    });
  }
}
