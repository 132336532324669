'use strict';

export default class Popover {
  constructor() {
    const initPopover = () => {
      $('[data-toggle="popover"]:not([data-has-popover])').each(function() {
        $(this).data('has-popover', true);

        $(this).attr('data-has-popover', true);

        $(this).popover();
      });
    };

    initPopover();

    $(document).on('handlebars.render.end', function() {
      initPopover();
    });
  }
}
