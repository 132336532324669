'use strict';

export default class CompareImage {
  constructor() {
    const setSize = () => {
      setTimeout(function(){
        $('#compare-compare img').each(function(){
          const width = $(this).closest('.card-image').css('width');
          console.log(width);
          $(this).css('width', width);
        });
      }, 500)
    };

    $('body').on('click', '#compare-tabs', setSize)

    $(window).on('resize', setSize)
  }
}
