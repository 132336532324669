'use strict';

export const ajax = {
  run: config => {
    return new Promise((resolve, reject) => {
      const { url, type = 'get', data = {}, dataType = 'json' } = config;

      const ajaxData = data;

      const ajaxConfig = {
        url,
        type,
        dataType,
        data: ajaxData,
        success: (json) => {
          return resolve(json)
        },
        error: reject
      }

      return $.ajax(ajaxConfig)
    });
  }
};
