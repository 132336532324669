'use strict';

export default class Toggler {
  constructor() {
    $.extend({
      toggler: {
        refreshBookmarks: ($obj, bookmarks=null) => {
          $.bookmark.render($('body'), bookmarks)
        }
      }
    })
  }
}
