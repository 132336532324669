'use strict';

export default class InViewport {
  constructor() {
    (function($) {
      $.extend({
        inViewport: ($obj, plus = 0) => {
          if ($obj.is(':visible')) {
            const rect = $obj.get(0).getBoundingClientRect();

            const windowHeight = (window.innerHeight || document.documentElement.clientHeight) + plus;

            const windowWidth = (window.innerWidth || document.documentElement.clientWidth) + plus;

            const vertInView = (rect.top <= windowHeight) && ((rect.top + rect.height) >= 0);

            const horInView = (rect.left <= windowWidth) && ((rect.left + rect.width) >= 0);

            return (vertInView && horInView);
          }
        }
      })
    })($);
  }
}
