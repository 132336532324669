'use strict';

import L from 'leaflet';
import 'leaflet.markercluster';
import { GestureHandling } from 'leaflet-gesture-handling';
import uniqueId from 'lodash/uniqueId'

export default class MapBlock {
  constructor() {
    (function($) {
      $.extend({
        mapBlock: {
          maps: {},
          init: ($obj) => {
            $obj.data('has-map', true);

            $obj.attr('data-has-map', true);

            const mapConfig = $obj.data('map-config');

            const mapCache = $.mapBlock.maps[mapConfig.id] || {
              map: null,
              markers: {}
            }

            if (mapCache.map) {
              return console.log('MapID', mapConfig.id, 'già presente nella pagina');
            }

            L.Map.addInitHook('addHandler', 'gestureHandling', GestureHandling);

            const icon = L.icon({
              iconUrl: sriConfig.marker,
              iconSize: [25, 41],
              iconAnchor: [12.5, 41],
              popupAnchor: [0, -41]
            });

            const markers = new L.MarkerClusterGroup({
              disableClusteringAtZoom: 15
            });

            (mapConfig.markers || []).forEach(marker => {
              const currentMarker = L.marker(marker.coords, {
                icon
              });

              const markerHtml = mapConfig.popupTemplate ? $.handlebars({
                template: mapConfig.popupTemplate,
                context: mapConfig.asFilters ? {
                  info: marker.info,
                  asFilters: true,
                  paramsConfig: {
                    name: mapConfig.name,
                    value: marker.value,
                    infiniteScroll: mapConfig.infiniteScroll
                  }
                } : marker.info,
                returnHtml: true
              }) : marker.info;

              currentMarker.bindPopup(markerHtml);

              mapCache.markers[marker.id || uniqueId('marker_')] = {
                marker: currentMarker,
                coords: marker.coords
              }

              markers.addLayer(currentMarker);
            });

            const mapProperties = {
              zoom: 13,
              maxZoom: 18,
              layers: markers,
              gestureHandling: true
            };

            if (mapConfig.markers.length === 1) {
              mapProperties.center = mapConfig.markers[0].coords;
            }

            const map = new L.map($obj.get(0), mapProperties);

            const bounds = [];

            (mapConfig.geoJsons || []).forEach(geoJson => {
              L.geoJSON(geoJson, {
                onEachFeature: function (feature, layer) {
                  bounds.push(layer.getBounds());

                  const geoJsonHtml = mapConfig.geoJsonPopupTemplate ? $.handlebars({
                    template: mapConfig.geoJsonPopupTemplate,
                    context: feature.info,
                    returnHtml: true
                  }) : '<h1>' + feature.info.title + '</h1><p>' + feature.info.text + '</p>';

                  layer.bindPopup(geoJsonHtml);
                }
              }).addTo(map);
            });

            L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
              attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>'
            }).addTo(map);

            if (mapConfig.markers.length > 1 && !mapConfig.preventMarkersFit) {
              bounds.push(...(mapConfig.markers || []).map(marker => {
                return marker.coords;
              }));
            }

            if (bounds.length > 2 && !mapConfig.preventMarkersFit) {
              map.fitBounds(bounds);
            }

            if (mapConfig.preventMarkersFit && mapConfig.bounds) {
              map.fitBounds(mapConfig.bounds);
            }

            mapCache.map = map;

            $.mapBlock.maps[mapConfig.id] = mapCache;
          },
        }
      });
    })($);

    $('[data-map-config]:not([data-has-map])').each(function() {
      $.mapBlock.init($(this));
    });

    $(document).on('handlebars.render.end', function() {
      $('[data-map-config]:not([data-has-map])').each(function() {
        $.mapBlock.init($(this));
      });
    });

    $('body').on('click', '[data-map-anchor]', function(e) {
      e.preventDefault();

      const mapAnchorConfig = $(this).data('map-anchor');

      const currentMap = $.mapBlock.maps[mapAnchorConfig.mapId] || {};

      if (currentMap.map) {
        const marker = currentMap.markers[mapAnchorConfig.markerId] || null;

        $('body, html').animate({
          scrollTop: $(currentMap.map._container).offset().top - $('.main-header').outerHeight()
        }, 250);

        if (marker) {
          currentMap.map.once('moveend', () => {
            marker.marker.openPopup();
          });

          currentMap.map.setView(marker.coords, 15);
        }
      }
    });
  }
}
