'use strict';

import debounce from 'lodash/debounce';

export default class Table {
  constructor() {
    (function($) {
      $.extend({
        sortableList: {
          init: () => {
            $.sortableList.debounceDelay = 10;

            $('body').on('change', '[data-sort-trigger]', function(e) {
              let $this = $(this);
              let config = $this.data('sort-trigger');

              let $container = $(`#${config.target}`);

              const name = $this.attr('name');
              const value = $this.val();

              if (name == 'sort-attr') {
                if (value == 'priority') {
                  $container.find('[data-move]').removeClass('disabled');

                  // hide select for ascending/descending order
                  $('[data-sort-trigger][name=sort-order]').each(function(el) {
                    const config2 = $(this).data('sort-trigger');

                    if (config2.target == config.target) {
                      $(this).closest('.form-group').hide();
                    }
                  })
                }
                else {
                  $container.find('[data-move]').addClass('disabled');

                  // hide select for ascending/descending order
                  $('[data-sort-trigger][name=sort-order]').each(function(el) {
                    const config2 = $(this).data('sort-trigger');

                    if (config2.target == config.target) {
                      $(this).closest('.form-group').show();
                    }
                  })
                }
              }

              $container.data(name, value);
              $.sortableList.sort($container);
            })

            $('body').on('mousedown', '[data-sortable] [data-draggable] [data-move]:not(.disabled)', function(e) {
              e.preventDefault()

              $.sortableList.dragStart(this, e);
            });

            $('body').on('mouseup', function(e) {
              $.sortableList.dragEnd(e);
            });

            $('body').on('mousemove', function(e) {
              $.sortableList.drag($.sortableList.dragContainer, e);
            });

            $('body').on('touchstart', '[data-sortable] [data-draggable] [data-move]:not(.disabled)', function(e) {
              e.preventDefault();

              if (!!$.sortableList.longPress) {
                return;
              }

              $.sortableList.longPress = setTimeout(() => {
                let originalEvent = (!e.originalEvent) ? e : e.originalEvent;
                $.sortableList.dragStart(this, {
                  clientX: originalEvent.touches[0].clientX,
                  clientY: originalEvent.touches[0].clientY
                });

                $('body').css('overflow', 'hidden');
              }, 800);
            })

            $('body').on('touchend', (e) => {
              if (!$.sortableList.longPress) {
                return;
              }

              clearTimeout($.sortableList.longPress);

              $.sortableList.dragEnd({});

              $.sortableList.longPress = null;
              $('body').css('overflow', 'auto');
            });

            $('body').on('touchmove', function(e) {
              e.preventDefault();

              if (!$.sortableList.longPress) {
                return;
              }

              let originalEvent = (!e.originalEvent) ? e : e.originalEvent;
              let clientX = originalEvent.touches[0].clientX;
              let clientY =  originalEvent.touches[0].clientY;

              $.sortableList.drag($.sortableList.dragContainer, {'clientX': clientX, 'clientY': clientY});

              let offset = window.innerHeight / 4;
              let bottomDist = window.innerHeight - clientY;
              if (bottomDist < offset) {
                let speed = Math.ceil(10 * (offset - bottomDist) / offset);
                window.scrollBy(0, speed);
              }
              else if (clientY < offset) {
                let speed = Math.ceil(10 * (offset - clientY) / offset);
                window.scrollBy(0, -speed);
              }
            })

            $(document).on('handlebars.render.end', $.sortableList.refresh);
            $.sortableList.refresh();
          },
          refresh: () => {
            $('[data-sortable]').each((i, el) => {
              $.sortableList.fillMissingData($(el));
            });

            //force reordering
            $('[data-sort-trigger]').trigger('change');
          },
          dragStart: (target, e) => {
            if (!!$.sortableList.dragEnabled || (!!e.button && e.button != 0)) {
              return;
            }

            let $row = $(target).closest('[data-draggable]');
            let $container = $row.closest('[data-sortable]');

            $row.addClass('dragging');
            $.sortableList.createDraggableRow($container, $row, e);

            $.sortableList.dragEnabled = true;
            $.sortableList.dragContainer = $container;
          },
          dragEnd: (e) => {
            if (!$.sortableList.dragEnabled) {
              return;
            }

            $('[data-sortable] [data-draggable]').removeClass('dragging');
            $('[data-sortable] [data-ghost]').remove();

            $.sortableList.rearrange($.sortableList.dragContainer);
            $.sortableList.sendUpdate($.sortableList.dragContainer);

            $.sortableList.dragEnabled = false;
            $.sortableList.dragContainer = null;
          },
          drag: ($container, e) => {
            if (!$.sortableList.dragEnabled) {
              return;
            }

            let lastUpdate = $.sortableList.lastUpdate || 0;
            if (Date.now() - lastUpdate < $.sortableList.debounceDelay) {
              return;
            }

            let $dragging = $container.find('.dragging[data-draggable]');
            let clientY = e.clientY + window.scrollY;

            // Getting all items except currently dragging and making array of them
            let siblings = $container.find('[data-draggable]:not(.dragging)').toArray();

            // Finding the sibling after which the dragging item should be placed
            let nextSibling = siblings.find(sibling => { return clientY <= $(sibling).offset().top + $(sibling).outerHeight() / 2; });
            if (!!nextSibling) {
              // Inserting the dragging item before the found sibling
              $(nextSibling).before($dragging);
            }
            else {
              //if no next sibling is found, insert the dragging item to the bottom
              $(siblings).last().after($dragging);
            }

            //update ghost position
            let $ghost = $container.find('[data-ghost]');
            let dragOffset = $ghost.data('drag-offset');
            $ghost.css({
              top: e.clientY - dragOffset.top,
              left: e.clientX - dragOffset.left
            });

            $.sortableList.lastUpdate = Date.now();
          },
          createDraggableRow: ($container, $row, e) => {
            let $ghost = $row.clone();
            $ghost.removeAttr('data-draggable').attr('data-ghost', '{}').addClass('ghost');
            $ghost.width($row.width());

            let $originalChildren = $row.children()

            $ghost.children().each((i, child) => {
              let childrenW = $originalChildren.eq(i).outerWidth();
              $(child).width(childrenW);
            });

            //calculate drag offset to align ghost position and mouse position
            let $dragToggle = $row.find('[data-move]');
            let dragOffset = {
              top: ($dragToggle.offset().top + $dragToggle.outerHeight() / 2) - $row.offset().top,
              left: ($dragToggle.offset().left + $dragToggle.outerWidth() / 2) - $row.offset().left
            }

            $ghost.css({
              top: e.clientY - dragOffset.top,
              left: e.clientX - dragOffset.left
            });

            $ghost.data('drag-offset', dragOffset);
            $row.before($ghost);
          },
          rearrange: ($container) => {
            $container.find('[data-draggable] [data-order]').each((i, el) => {
              $(el).attr('data-order', i);
              $(el).data('order', i);
            });
          },
          sendUpdate: ($container) => {
            let config = $container.data('sortable');

            if (typeof config != 'object' || !config.url) {
              return;
            }

            let list = [];
            $container.find('[data-order]').each((i, el) => {
              list.push($(el).data('id'));
            });

            config.params = config.params || {};
            //remove handlebars generated id
            delete(config.params.__id);

            $.ajax({
              url: config.url,
              method: config.method || 'POST',
              data: {
                ...config.params,
                "ids": list.join(',')
              },
              success: (res) => {
                console.log(res);
              },
              error: (err) => {
                console.error(err);
              }
            })
          },
          sort: ($container) => {
            const sortAttr = $container.data('sort-attr') || 'priority';
            const sortOrder = $container.data('sort-order') || 'asc';

            const $sortColumn = $container.find(`[data-sort-column=${sortAttr}]`);
            let index = $sortColumn.index();

            let $tBody = $container.find('tbody').first();
            let $items = $tBody.find('[data-draggable]');

            $items.detach();
            $items = $items.toArray();

            // if sort attribute has not been found, sort by priority
            if (index < 0) {
              $items.sort((a, b) => {
                return $(a).find('[data-order]').data('order') - $(b).find('[data-order]').data('order');
              })
            }
            else {
              $items.sort((a, b) => {
                const $a = $(a).children(`:nth-child(${index+1})`);
                const $b = $(b).children(`:nth-child(${index+1})`);

                let aVal = $a.attr('data-sort-value') || $a.text();
                let bVal = $b.attr('data-sort-value') || $b.text();

                if (sortOrder == 'desc') {
                  return bVal.localeCompare(aVal);
                }

                return aVal.localeCompare(bVal);
              })
            }

            $items = $($items);
            $tBody.append($items);
          },
          fillMissingData: ($container) => {
            let $items = $container.find('[data-order]');
            let maxOrder = -1;

            // get the max data-order value from items
            $items.each((i, el) => {
              if (isNaN($(el).data('order'))) {
                return;
              }

              maxOrder = Math.max(maxOrder, $(el).data('order'));
            })

            // assign a positive value to all negative data-order values
            maxOrder++;
            $items.each((i, el) => {
              if (isNaN($(el).data('order')) || $(el).data('order') < 0) {
                $(el).data('order', maxOrder);
                $(el).attr('data-order', maxOrder);
                maxOrder++;
              }
            });
          }
        }
      })
    })($);

    $.sortableList.init();

    $('body').on('keyup', '[data-table-search]', debounce(function () {
      const valueToSearch = new RegExp($(this).val(), 'ig');

      const $target = $($(this).data('table-search'));

      $target.find('tbody tr').each(function() {
        const currentText = $(this).text().trim();

        $(this).removeClass('d-none');

        if (!valueToSearch.test(currentText)) {
          $(this).addClass('d-none');
        }
      });
    }, 300));
  }
}
