'use strict';

import _ from 'lodash';

export default class Header {
  constructor() {
    const changeMenuType = function() {
      const scrollPosition = $(window).scrollTop();

      const isSmall = $('body').hasClass('small-menu');

      const smallClass = 'small-menu'

      if (scrollPosition > 200) {
        $('body').addClass(smallClass);

        if (!isSmall) {
          setTimeout(() => {
            $('body').trigger('small-menu.changed');
          }, 1000);
        }

        return;
      }

      $('body').removeClass(smallClass);

      if (isSmall) {
        setTimeout(() => {
          $('body').trigger('small-menu.changed');
        }, 1000);
      }
    };

    changeMenuType();

    $(window).on('scroll', _.throttle(changeMenuType, 100));

    $('#main-menu').on('show.bs.collapse hide.bs.collapse', function(e) {
      $('html')[(e.type === 'show' ? 'add' : 'remove') + 'Class']('with-modal');
    });
  }
}
