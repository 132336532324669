'use strict';

import Keyboard from 'simple-keyboard';
import layouts from 'simple-keyboard-layouts';
import uniqueId from 'lodash/uniqueId';

const layoutLabels = {
  arabic: 'Arabo',
  assamese: 'assamese',
  belarusian: 'Bielorusso',
  burmese: 'burmese',
  chinese: 'Cinese',
  czech: 'czech',
  english: 'Inglese',
  farsi: 'Farsi',
  rench: 'Francese',
  georgian: 'Georgiano',
  german: 'Tedesco',
  gilaki: 'gilaki',
  greek: 'Greco',
  hebrew: 'Ebraico',
  hindi: 'Hindi',
  italian: 'Italiano',
  japanese: 'Giapponese',
  kannada: 'Kannada',
  korean: 'Coreano',
  nigerian: 'Nigeriano',
  norwegian: 'Norvegiese',
  polish: 'Polacco',
  russian: 'Russo',
  sindhi: 'Sindhi',
  spanish: 'Spagnolo',
  swedish: 'Svedese',
  thai: 'Thai',
  turkish: 'Torco',
  ukrainian: 'Ucraino',
  urdu: 'Urdu',
  uyghur: 'uyghur'
};

export default class VirtualKeyboard {
  constructor() {
    const keyboards = {};

    const allLayouts = new layouts();

    const initVirtualKeyboard = () => {
      $('[data-vk]:not([data-has-vk])').each(function () {
        const $input = $(this);

        $input.data('has-vk', true);

        $input.attr('data-has-vk', true);

        const $parent = $input.parent();

        const currentId = uniqueId('virtual-keyboard_');

        const $langSwitcher = $('<select>', {
          'data-vk-change-language': currentId,
          class: 'form-control',
          value: 'italian'
        });

        Object.keys(layoutLabels).forEach(language => {
          $langSwitcher.append($('<option>', {
            value: language,
            selected: language === 'italian'
          }).html(layoutLabels[language] || language));
        });

        const $toggler = $('<a>', {
          class: 'virtual-keyboard-toggler btn btn-default',
          href: '#' + currentId,
          'data-toggle': 'collapse'
        }).html($('<i>', {
          class: 'material-icons'
        }).html('keyboard'));

        let $currentInputGroupAppend = $parent.children('.input-group-append');

        if ($parent.hasClass('input-group')) {
          if (!$currentInputGroupAppend.length) {
            $currentInputGroupAppend = $('<div>', {
              class: 'input-group-append'
            });

            $parent.append($currentInputGroupAppend);
          }

          $currentInputGroupAppend.prepend($toggler);
        } else {
          $parent.append($('<div>', {
            class: 'input-group'
          }).html($input).append($('<div>', {
            class: 'input-group-append'
          }).html($toggler)))
        }

        const $keyBoardDom = $('<div>', {
          class: 'simple-keyboard'
        });

        $parent.append($('<div>', {
          id: currentId,
          class: 'virtual-keyboard-container collapse'
        }).html($('<div>', {
          class: 'form-group'
        }).html($langSwitcher)).append($keyBoardDom));

        const onChange = input => {
          $input.val(input);
        };

        keyboards[currentId] = new Keyboard($keyBoardDom.get(0), {
          onChange,
          layout: allLayouts.layouts.italian
        });

        $parent.addClass('virtual-keyboard-dialog');
      });
    };

    initVirtualKeyboard();

    $(document).on('handlebars.render.end', initVirtualKeyboard);

    $('body').on('click', function(e) {
      const $currentTarget = $(e.target);

      const inVk = !(!(!$currentTarget.is('input') && ($currentTarget.hasClass('virtual-keyboard-dialog') || $currentTarget.closest('.virtual-keyboard-dialog').length)));

      if (!inVk) {
        $('.virtual-keyboard-dialog .virtual-keyboard-container.show').each(function() {
          $(this).collapse('hide');
        });
      }
    });

    $('body').on('change', '[data-vk-change-language]', function() {
      const keyboardId = $(this).data('vk-change-language');

      const language = $(this).val();

      keyboards[keyboardId].setOptions({
        layout: allLayouts.layouts[language]
      });
    });
  }
}
