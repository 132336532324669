'use strict';

import { ajax } from '../../_scripts/commons/ajax';
import debounce from 'lodash/debounce';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';

export default class SearchBlock {
  constructor() {
    (function ($) {
      $.extend({
        runSelect2: (selector = '[data-select-2]:not([data-has-select2])') => {
          $(selector).each(function () {
            const $obj = $(this);

            $obj.data('has-select2', true);

            $obj.attr('data-has-select2', 'true');

            const options = $obj.data('options') || null;

            const currentConfig = $obj.data('select-2') || {};

            if (options) {
              return ajax.run({
                url: options.url,
              }).then(json => {
                $.handlebars({
                  context: {
                    allowClear: currentConfig.allowClear,
                    options: json.data,
                  },
                  container: $obj,
                  template: options.template
                });

                runInnerSelect2($obj);
              })
            }

            runInnerSelect2($obj);
          });
        },
        searchBlock: {
          init: $obj => {
            $obj.data('renderized', true);

            $obj.attr('data-renderized', true);

            const { context, container, template } = $obj.data('form-config');

            $(document).trigger('sb:init:start');

            $.handlebars({
              context,
              container,
              template
            });

            $(document).trigger('sb:init:end');
          },
          btnValidator: {
            init: $form => {
              $form.find('[data-btn-validators]').each(function () {
                const validators = $(this).data('btn-validators') || [];

                let isValid = true;

                validators.forEach(validator => {
                  if (!$.searchBlock.btnValidator[validator.type]) {
                    return console.log('Validatore', validator.type, 'non trovato');
                  }

                  if (!$.searchBlock.btnValidator[validator.type]($form, validator)) {
                    isValid = false;
                  }
                });

                $(this)[isValid ? 'removeAttr' : 'attr']('disabled', true);
              });
            },
            required: ($form, validator) => {
              let fields = [];

              $form.find(validator.field).each(function () {
                const isValid = !(!($(this).is(':checkbox') || $(this).is(':radio') ? $(this).is(':checked') : $(this).val()));

                if (isValid) {
                  fields.push($(this));
                }
              });

              let isValid = !(!fields.length);

              if (validator.min) {
                isValid = fields.length >= validator.min
              }

              if (validator.max) {
                isValid = fields.length <= validator.max
              }

              return isValid;
            }
          },
          preview: $form => {
            const items = []

            const getValue = $obj => {
              if ($obj.is('input')) {
                if ($obj.is(':radio') || $obj.is(':checkbox')) {
                  return $obj.is(':checked') ? true : '';
                }

                return $obj.val();
              }

              if ($obj.is('select')) {
                const options = [];

                $obj.find('option:selected').each(function () {
                  options.push($(this).text());
                });

                return options.join(' OR ');
              }
            };

            const getPreviewLabel = $obj => {
              const tmpPreviewLabel = $obj.data('preview-label');

              const $input = $obj.find('[type="radio"], [type="checkbox"]');

              const isCheckboxOrRadio = !(!$input.length);

              const isChecked = isCheckboxOrRadio ? !(!$input.is(':checked')) : true;

              if (!tmpPreviewLabel || !isChecked) {
                return null
              }

              return tmpPreviewLabel;
            };

            $form.find('[data-group-label]').each(function () {
              const subItems = [];

              const names = [];

              $(this).find('[data-preview-group]').each(function () {

                let previewGroupTemplate = $(this).data('preview-group');

                let groupWords = [];

                const groupItems = [];

                $(this).find('[data-type]:not([data-type="label"]):not([data-type="hidden"]), [data-type="hidden"][data-preview-label]').each(function () {
                  groupItems.push(getPreviewLabel($(this)) || getValue($(this).find('[name]')));
                });

                previewGroupTemplate.match(/(\[[0-9]+\])/ig).forEach(item => {
                  const index = parseInt(item.replace(/(\[|\])/ig, ''));

                  groupWords.push(groupItems[index]);
                });

                if (!groupWords.filter(item => !item).length) {
                  let namesElem = []

                  $(this).find('[name]').each(function () { namesElem.push($(this).attr('name')) });

                  names.push(namesElem);

                  groupItems.forEach((groupWord, index) => {
                    previewGroupTemplate = previewGroupTemplate.replace('[' + index + ']', groupWord);
                  });

                  subItems.push(previewGroupTemplate);
                }
              });

              const singlePreviews = []

              $(this).find('[data-preview]').each(function () {
                const value = getPreviewLabel($(this)) || getValue($(this).find('[name]'));

                if (value || value === 0) {
                  let namesElem = []

                  $(this).find('[name]').each(function () { namesElem.push($(this).attr('name')) });

                  names.push(namesElem);

                  singlePreviews.push($(this).data('preview') + (typeof value === 'boolean' ? '' : value));
                }
              });

              if (singlePreviews.length) {
                subItems.push(...singlePreviews);
              }

              items.push({
                label: $(this).data('group-label'),
                items: subItems.map((item, index) => {
                  const lastRE = new RegExp('__last(.*?)tsal__', 'ig');

                  return item.replace(lastRE, index === subItems.length - 1 ? '' : '$1');
                }),
                names: names
              });
            });

            $('[data-info-block]').each(function () {
              const infoBlockConfig = $(this).data('info-block');

              $.handlebars({
                context: items.filter(item => {
                  return !(!item.items.length)
                }),
                container: $(this),
                template: infoBlockConfig.template
              });

              if ($(this).parent().find('.info-row-content-item').length) {
                $(this).closest('.search-block-item').find('.search-block-fixed-info-label a').addClass('active');
              } else {
                $(this).closest('.search-block-item').find('.search-block-fixed-info-label a').removeClass('active');
              }
            });
          },
          hideIf: {
            init: () => {
              $('[data-hide-if]:not([data-hide-if="false"])').each(function () {
                const hideIfConfig = $(this).data('hide-if');

                const $obj = $(this);

                Object.entries(hideIfConfig).forEach(([key, value]) => {
                  if (!$.searchBlock.hideIf[key]) {
                    return;
                  }

                  try {
                    $.searchBlock.hideIf[key]($obj, value);
                  } catch (err) {
                    console.log(err);
                  }
                });
              });
            },
            noOptions: ($obj, hideIfSelector) => {
              const $selector = $(hideIfSelector);

              if ($selector.find('option:not([data-empty])').length) {
                return $obj.removeClass('d-none');
              }

              return $obj.addClass('d-none');
            },
            notHasValue: ($obj, hideIfSelector) => {
              const $selector = $(hideIfSelector.field);

              if ($selector.val() === hideIfSelector.value) {
                return $obj.removeClass('d-none');
              }

              return $obj.addClass('d-none');
            }
          },
          fillRelated: $obj => {
            const { field, paramName, type = 'post', url, optionsTemplate } = $obj.data('fill-related');

            const data = {};

            let value = $obj.val() || '*';

            data[paramName || $obj.attr('name')] = value;

            $.ajax({
              type,
              dataType: 'json',
              url,
              data,
              success: (json) => {
                $.handlebars({
                  context: {
                    options: json.data,
                    placeholder: $(field).data('placeholder')
                  },
                  container: field,
                  template: optionsTemplate
                });

                $(field).trigger('change');
              }
            });
          },
          changeNameCount: (obj, count, currentKey) => {
            if (isArray(obj)) {
              return obj.map(item => {
                return $.searchBlock.changeNameCount(item, count);
              });
            }

            if (isObject(obj)) {
              Object.entries(obj).forEach(([key, value]) => {
                obj[key] = $.searchBlock.changeNameCount(value, count, key);
              });

              return obj;
            }

            if (currentKey === 'name') {
              return obj.replace(/\[[^\]]*]/i, '[' + count + ']');
            }

            return obj;
          },
          duplicateRow: $obj => {
            const { template, context } = $obj.data('duplicate');

            const container = $obj.closest('[data-type="row"]');

            const newContext = $.searchBlock.changeNameCount(context.row, container.siblings().length + (context.startFrom || 0) + 1);

            newContext.deletable = true;

            $.handlebars({
              context: newContext,
              container,
              append: 'insertBefore',
              template
            });
          },
          reloadRow: $obj => {
            if ($obj.data('prevent-reload-row')) {
              $obj.removeData('prevent-reload-row');

              return;
            }

            const container = $obj.closest('.search-block-item');

            let data = $obj.data('reload-row');

            if (!isObject(data)) data = JSON.parse(JSON.parse(data));

            const context = data[$obj.val()];

            context.fields[0].reloadRow = JSON.stringify(data);

            $.handlebars({
              context,
              container,
              append: 'html',
              template: '#search-block-row-template'
            });

            $.searchBlock.preview($('form'));
          }
        }
      })
    })($);

    $(document).on('sb:init:end', function () {
      $.searchBlock.hideIf.init();

      $.searchBlock.preview($('form'));
    });

    $('body').on('change', 'select', function () {
      $.searchBlock.hideIf.init();
    });

    $('body').on('click', '[data-duplicate]', function (e) {
      e.preventDefault();

      $.searchBlock.duplicateRow($(this));
    });

    $('body').on('click', '[data-list-changer] [data-list-changer-value]', function (e) {
      e.preventDefault();

      const listChangerConfig = $(this).closest('[data-list-changer]').data('list-changer');

      const value = $(this).data('list-changer-value');

      $('[name="' + listChangerConfig.input.name + '"]').val(value);

      $('[name="' + listChangerConfig.select.name + '"]').val(listChangerConfig.select.value);

      $('[name="' + listChangerConfig.select.name + '"]').change();

      $(this).closest('.modal').modal('toggle');
    });

    $('body').on('change', '[name]', debounce(function () {
      const $form = $(this).closest('form');

      if ($form.length) {
        $.searchBlock.preview($form);
      }
    }, 500));

    $('body').on('keyup change', '[name]', debounce(function () {
      const $form = $(this).closest('form');

      if ($form.length) {
        $.searchBlock.btnValidator.init($form);
      }
    }, 500));

    $('body').on('change', '[data-fill-related]:not([data-fill-related="false"]):not([data-fill-related=""])', function () {
      $.searchBlock.fillRelated($(this));
    });

    $('body').on('change', 'select[data-reload-row]:not([data-reload-row="false"])', function () {
      $.searchBlock.reloadRow($(this));
    });

    $('body').on('change', '[data-coords] input', function () {
      $(this).closest('[data-coords]').find('input').each(function () {
        if (!$(this).val()) {
          $(this).val('0');
        }
      });
    });

    $('body').on('click', '[data-reset-fields]', function () {
      const names = $(this).data('reset-fields');

      const $btn = $(this);

      names.forEach(name => {
        const $obj = $('[name="' + name + '"]');

        if ($obj.is('input') && !$obj.is(':checkbox')) {
          $obj.val('');

          $obj.trigger('change');
        }

        if ($obj.is(':checkbox')) {
          $obj.each(function () {
            const label = $(this).siblings('label').html();

            const currentLabel = $btn.data('label').includes('=') ? $btn.data('label').split(' = ')[1] : $btn.data('label');

            if (label === currentLabel) {
              $(this).trigger('click');
            }
          })
        }

        if ($obj.is('select')) {
          $obj[0].selectedIndex = 0;

          if ($obj.attr('multiple')) $obj.val(null);

          $obj.trigger('change');
        }
      })
    });

    $('[data-form-config]:not([data-renderized])').each(function () {
      $.searchBlock.init($(this));
    });

    const runInnerSelect2 = $obj => {
      const defaultConfig = {
        width: 'resolve',
        containerCssClass: 'form-control'
      };

      const currentConfig = $obj.data('select-2') || {};

      if (currentConfig.ajax) {
        //allow use of serialized functions as url
        currentConfig.ajax.url = eval(currentConfig.ajax.url);
        currentConfig.ajax.processResults = data => {
          return {
            results: data.data.map(item => {
              return {
                id: item.value,
                text: item.label
              };
            })
          };
        };
      }

      $obj.select2({
        ...defaultConfig,
        ...currentConfig
      });
    };

    $('body').on('click', '[data-toggle="row"]', function (e) {
      e.preventDefault();

      $(this).closest('[data-type="fixed-info-row"]').toggleClass('active');
    });

    $(document).on('handlebars.render.end', function () {
      $.runSelect2();

      $('[data-fill-related]:not([data-has-fill-related]):not([data-fill-related="false"]):not([data-fill-related=""])').each(function () {
        $(this).data('has-fill-related', true);

        $(this).attr('data-has-fill-related', true);

        if ($(this).val()) {
          $.searchBlock.fillRelated($(this));
        }
      });
    });

    //Utente clicca 'Annulla/reset' nel searchblock
    $('body').on('reset', 'form', function () {
      $(this).find('[name]:not([type="hidden"])').each(function () {
        if ($(this).is('input')) {
          if ($(this).val()) {
            $(this).val('');

            $(this).trigger('change');
          }

          if ($(this).is(':checked')) {
            $(this).trigger('click');
          }
        }

        if ($(this).is('select') && !$(this).data('reload-row')) {
          $(this)[0].selectedIndex = 0;

          if ($(this).attr('multiple')) {
            $(this).val(null);
          }

          $(this).trigger('change');
        }
      })
    });

    $('body').on('click', '[data-delete-row]', function () {
      const $row = $(this).closest('[data-type="row"]');

      $row.remove();

      $.searchBlock.preview($('form'));
    });

    $('body').on('click', function (e) {
      const $target = $(e.target);

      const isToggler = $target.data('toggle') === 'row';

      const isBlock = $target.hasClass('search-block-fixed-info-row');

      const hasParent = !(!$target.closest('.search-block-fixed-info-row').length);

      if (!isToggler && !isBlock && !hasParent) {
        $('[data-type="fixed-info-row"]').removeClass('active');
      }
    });

    $.runSelect2();
  }
}
