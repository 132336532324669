'use strict';

import debounce from 'lodash/debounce';

export default class NavigationCache {
  constructor() {
    const myStorage = window.sessionStorage;

    (function ($) {
      let navigationCacheTimeout = null;

      $.extend({
        navigationCache: {
          recovered: false,
          currentUrl: () => {
            if (!window.location.hash) {
              window.location.hash = (new Date()).getTime();
            }

            return window.location.href.replace(/(\?|#)bc=1/ig, '');
          },
          init: () => {
            if (navigationCacheTimeout) {
              clearTimeout(navigationCacheTimeout);
            }

            navigationCacheTimeout = setTimeout(() => {
              const $navigationCache = $('[data-navigation-cache]');

              const html = $navigationCache.html();

              const htmls = JSON.parse(myStorage.getItem('htmls') || '{}');

              htmls[$.navigationCache.currentUrl()] = {
                html
              };

              myStorage.setItem('htmls', JSON.stringify(htmls));
            }, 300);
          },
          currentHtml: () => {
            const htmls = JSON.parse(myStorage.getItem('htmls') || '{}');

            let currentUrl = $.navigationCache.currentUrl();

            const plusGroup = /\+{2,}/ig;

            currentUrl = currentUrl.replaceAll(plusGroup, '+').replaceAll('?', '\\?').replaceAll('[', '\\[').replaceAll(']', '\\]').replaceAll('*', '\\*');

            const regexp = new RegExp('^' + currentUrl, 'g');

            let currentKey;

            for (var key in htmls) {
              if (key.match(regexp)) {
                currentKey = key;
              }
            }

            const currentHtml = htmls[currentKey] || {};

            return currentHtml;
          }
        }
      });
    })($);

    if ($('[data-navigation-cache]').length) {
      $('body').on('keyup change', 'input[type="text"], input[type="search"], input[type="number"]', function () {
        $(this).attr('value', $(this).val());
      })

      $('body').on('click', 'input[type="checkbox"], input[type="radio"]', function () {
        if ($(this).is(':checked')) {
          $(this).attr('checked', true);

          $(this).removeAttr('not-checked');
        } else {
          $(this).removeAttr('checked');

          $(this).attr('not-checked', true);
        }
      })

      $('body').on('change', 'select:not([multiple])', function () {
        let val = $(this).val()

        $(this).find('option').each(function () {
          if ($(this).attr('value') === val) {
            $(this).attr('selected', true);

            $(this).removeAttr('not-selected');
          } else {
            $(this).removeAttr('selected');

            $(this).attr('not-selected', true);
          }
        })
      })

      $('body').on('change', 'select[multiple]', function () {
        let val = $(this).val()

        $(this).find('option').each(function () {
          if (val.includes($(this).attr('value'))) {
            $(this).attr('selected', true);

            $(this).removeAttr('not-selected');
          } else {
            $(this).removeAttr('selected');

            $(this).attr('not-selected', true);
          }
        })
      })

      const currentUrl = $.navigationCache.currentUrl();

      const htmls = JSON.parse(myStorage.getItem('htmls') || '{}');

      const {
        html: currentHtml,
        scrollTop
      } = $.navigationCache.currentHtml();

      if (currentHtml) {
        delete htmls[currentUrl];

        myStorage.setItem('htmls', JSON.stringify(htmls));

        $('[data-navigation-cache]').html('');

        const $html = $(currentHtml);

        $html.find('[data-has-select2="true"]').each(function () {
          $(this).next().remove();
        });

        $html.find('[data-name]').each(function () {
          $(this).attr('name', $(this).data('name'));
        })

        $('[data-navigation-cache]').html('<!-- From cache -->').append($html);

        $.runSelect2('[data-navigation-cache] [data-has-select2="true"]');

        $('[data-navigation-cache]').find('[data-has-range-picker="true"]').each(function () {
          $.rangePicker.reset($(this));
        });

        $('[data-autocomplete]').each(function () {
          $.autocomplete.init($(this));
        });

        $.sticky.reset();

        $.navigationCache.recovered = true;

        setTimeout(() => {
          $('html, body').animate({
            scrollTop
          }, 200);
        }, 1000);
      }

      $('[data-navigation-cache]').addClass('active');

      $(document).on('handlebars.render.end', debounce($.navigationCache.init, 300));

      $(window).on('beforeunload', function () {
        if ($('[data-navigation-cache]').length) {
          const htmls = JSON.parse(myStorage.getItem('htmls') || '{}');

          htmls[currentUrl] = { scrollTop: $(window).scrollTop(), html: $('[data-navigation-cache]').html() }

          myStorage.setItem('htmls', JSON.stringify(htmls));
        }
      });
    }
  }
};
